import React from "react";
import FeatureCell from "../FeatureCell/FeatureCell";
import "./FeatureList.css"
import { Feature } from "../../model/feature";
import { User } from "../../model/user";

interface FeaturesListProps {
    features: Feature[];
    user: User;
    onLike: (id: string) => void;
    onDislike: (id: string) => void;
}

function FeatureList(props: FeaturesListProps) {

    return (
        <ul className="features-list">

            {props.features != null
                ? props.features.map((feature) =>
                    <FeatureCell
                        key={feature.id}
                        feature={feature}
                        user={props.user}
                        onLike={(featureID) => props.onLike(featureID)}
                        onDislike={(featureID) => props.onDislike(featureID)}
                    />
                )
                : <div className="empty-contianer"><p>Nothing here yet!</p></div>
            }

        </ul>
    )
}

export default FeatureList;