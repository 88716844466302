import React from "react";
import "./ReplySection.css"

export interface ReplyProps {
    content: string;
}

function ReplySection(props: ReplyProps) {
    return (
        <div className="reply-section">
            <p className="By">Developer</p>
            <p className="reply-content">{props.content}</p>
        </div>
    )
}

export default ReplySection;