import React from "react";
import "./ProcessTag.css"

export interface ProcessProps {
    label: string;
}

function ProcessTag(props: ProcessProps) {
    return (
        <div className="process-tag">
            {props.label}
        </div>
    )
}

export default ProcessTag;