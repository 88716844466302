import React, {useEffect} from "react";
import "./DetailsView.css";

interface DetailsViewProps {
    documentTitle?: string;
    content?: string;
    html?: string;
}

function DetailsView(props: DetailsViewProps) {

    useEffect(() => {
        if (props.documentTitle !== undefined) {
            document.title = props.documentTitle
        }
    })

    const createEvent = () => {
        if (props.html !== undefined) {
            return { __html: props.html }
        }
    }

    return (
        <div className="details-view">
            {
                props.content !== undefined
                ? <div className="content-html" >{props.content}</div>
                : <div className="content-html" dangerouslySetInnerHTML={createEvent()}></div>
            }

        </div>
    )
}

export default DetailsView;