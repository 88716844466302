import React from "react";
import ProcessTag from "../ProcessTag/ProcessTag";
import ReplySection from "../ReplySection/ReplySecion";
import "./FeatureCell.css"

import {Feature} from "../../model/feature";
import {User} from "../../model/user";

export interface FeatureCellProps {
    feature: Feature;
    user: User;
    onLike: (featureId: string) => void;
    onDislike: (featureId: string) => void;
}

function FeatureCell(props: FeatureCellProps) {
    const feature = props.feature
    const featureId = feature.id as string

    let processTag
    if (feature.process === 1) {
        // in progress
        processTag = <ProcessTag label="IN PROGRESS"/>
    } else if (feature.process === 2) {
        // done
        processTag = <ProcessTag label="DONE"/>
    }

    let replySection
    if (feature.reply !== null && feature.reply !== "") {
        replySection = <ReplySection content={feature.reply} />
    }

    // likes & dislikes status
    const isLiked = props.user.likes !== null && props.user.likes.includes(featureId)
    const likeSection = (
        <div
            className={`small-button${isLiked ? " liked" :  ""}`}
            onClick={() => {
                isLiked ? console.log("cancel like") : props.onLike(featureId)
            }}
        >
            <i className="material-icons">thumb_up</i>
            <p>{feature.likes.toLocaleString()}</p>
        </div>
    )

    const isDisliked = props.user.dislikes !== null && props.user.dislikes.includes(featureId)
    const dislikeSection = (
        <div
            className={`small-button${isDisliked ? " disliked" : ""}`}
            onClick={() => {
                isDisliked ? console.log("cancel dislike") : props.onDislike(featureId)
            }}
        >
            <i className="material-icons">thumb_down</i>
            <p>{feature.dislikes.toLocaleString()}</p>
        </div>
    )

    return (
        <li className="feature-cell">
            {processTag}
            <p className="feature-title">{feature.title.toUpperCase()}</p>

            <p className="feature-content">{feature.content}</p>

            <div className="actions-group">
                {likeSection}
                {dislikeSection}
            </div>

            {replySection}

        </li>
    )
}

export default FeatureCell