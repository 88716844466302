import React from "react";
import {Route, Switch} from "react-router-dom";

import FeaturesRootView from "../views/features/FeaturesRootView";
import FaqRootView from "../views/faq/FaqRootView";
import TermsView from "../views/terms/TermsView/TermsView";
import PrivacyView from "../views/privacy/PrivacyView/PrivacyView";

function RootRouters() {
    return (
        <Switch>
            <Route path="/faq">
                <FaqRootView/>
            </Route>
            <Route path="/terms">
                <TermsView/>
            </Route>
            <Route path="/privacy">
                <PrivacyView />
            </Route>
            <Route path="/features">
                <FeaturesRootView/>
            </Route>
        </Switch>
    );
}

export default RootRouters;