import axios from "axios";

const DEV_API_URL = "https://devapi.hiyak.com/api";
const PROD_API_URL = "https://api.hiyak.com/api"

const API_URL = process.env.NODE_ENV === "development"
    ? DEV_API_URL
    : PROD_API_URL

export const AppAPI = axios.create({
    baseURL: API_URL,
})

export const initToken =(token: string) => {
    // AppAPI.defaults.headers.Authorization = 'Bearer ' + token;
    AppAPI.defaults.headers["x-token"] = token
}