import { User } from "../model/user";
import {AppAPI} from "./index";

export const fetchUser = async (): Promise<User|undefined> => {
    try {
        const response = await AppAPI.get<User>("/users/")
        return response.data
    } catch (e) {
        throw e.response.data
    }
}