import {Faq, FaqCategory} from "../model/faq";

export const faqCategories: FaqCategory[] = [
    {id: "basic", name: "Basic"},
    {id: "features", name: "Features"},
    {id: "video_chat", name: "Video Chat"},
    {id: "stories", name: "Stories"},
    {id: "coins", name: "Coins"},
    {id: "profiles", name: "Profiles"},
    {id: "others", name: "Others"},
]

export const faqs: Faq[] = [
    // basic
    {
        id: "101",
        title: "What is HIYAK?",
        content: `<p>HIYAK is a social video chat app where you can meet strangers and make new friends from all around the world. Create video stories, get followers, follow your favorite people, like each other, and have a free 1:1 random video chats. And you can also stay connected with people you like with direct video calls. We’re the best video chat app that’s available in more than 190 countries.</p>`,
        categoryId: "basic"
    },
    {
        id: "102",
        title: "How to make new friends on HIYAK?",
        content: `
            <p>The world is changing, and people are using technology to develop new connections online. Now you don’t have to just meet local people. You can meet new people from almost anywhere!</p>
            <p>First thing’s first. Sign up. Fill out your info— and show the world what you’ve got!</p>
            <p>Set your preferences on who you want to meet according to gender and location.</p>
            <p>Swipe to get to see stories, get matches, and see your chat history.</p>
            <p>Scroll through the stories and decide who you’d like to meet.</p>
            <p>Check out their profile. Tap the heart to send a like, follow them, send a gift or make a random video call.</p>
            <p>You both have 30 seconds to decide if you want to have a random video chat with each other. New random chats are free for 10 minutes.</p>
            <p>Want to chat with the same person again and again? Make a direct call and stay connected.</p>
            <p>HIYAK is free to use. Random video chats are free. And you can purchase coins to make direct calls and send exclusive gifts. That’s the easiest way to show your new friends how much you care.</p>
            <p>Download the HIYAK app for iOS and Android.</p>`,
        categoryId: "basic"
    },
    {
        id: "103",
        title: "How do I start using HIYAK?",
        content: `<p>Download HIYAK today. Get it on Google Play or the App Store. Create an account.</p>`,
        categoryId: "basic"
    },
    {
        id: "104",
        title: "How to create your account using Facebook?",
        content: `<p>
            Tap Join Now<br>
            Tap Continue with Facebook<br>
            Tap Continue on the pop-up<br>
            Fill out your profile info<br>
            Post a story</p>`,
        categoryId: "basic"
    },
    {
        id: "105",
        title: "How to create your account with your phone number?",
        content: `<p>
            Tap Join Now<br>
            Tap Continue with Phone<br>
            Type in your phone number (make sure you choose the correct country code)<br>
            Tap Continue<br>
            Enter the code sent to your phone via text<br>
            Fill out your profile info<br>
            Post a story</p>`,
        categoryId: "basic"
    },
    {
        id: "106",
        title: "How to create your account with Google?",
        content: `<p>
            Tap Join Now<br>
            Tap Continue with Google<br>
            Tap Continue on the pop-up.<br>
            Choose the Google account you want to use<br>
            Fill out your profile information.<br>
            Post a story</p>`,
        categoryId: "basic"
    },
    {
        id: "107",
        title: "How to create an account with Apple?",
        content: `<p>
            Tap Continue with Apple<br>
            Tap Continue with Passcode<br>
            Type in your Passcode<br>
            Fill out your profile information.<br>
            Post a story</p>`,
        categoryId: "basic"
    },
    {
        id: "108",
        title: "Is the HIYAK app free?",
        content: `
            <p>Totally! You can download HIYAK today and make new friends in minutes.</p>
            <p>If you want to send exclusive gifts, make direct calls, or have premium search filters, you’ll need to buy coins or send an invitation to your friends with your invite link. When 5 friends Tap the link, we’ll boost your account with 1000 free coins! And when a new user signs up, you’ll get 100 bonus coins! It can take 36 hours for free coins to show up in your account. Please be patient with us.</p>`,
        categoryId: "basic"
    },
    {
        id: "109",
        title: "What is the minimum age requirement to sign up for HIYAK?",
        content: `<p>You need to be 18 years or older to sign up and use our app.</p>`,
        categoryId: "basic"
    },

    // features
    {
        id: "201",
        title: "What are all the features available on HIYAK?",
        content: `<p>
            √ Unlimited free random video chat for 10 minutes<br>
            √ Countdown timer for fast matching— you have 30 seconds to decide if you want to have a random chat<br>
            √ Add video stories<br>
            √ Watch, like, and follow other people’s stories<br>
            √ Send gifts<br>
            √ Make direct video calls to people you Tap with<br>
            √ Connect with someone by tapping the heart to show your interest<br>
            √ Get coins for exclusive gifts, premium search filters, and direct video calls<br>
            √ We’re available in over 190 countries</p>`,
        categoryId: "features"
    },
    {
        id: "202",
        title: "What are hearts for?",
        content: `<p>Tap a heart to like someone or their stories.</p>`,
        categoryId: "features"
    },
    {
        id: "203",
        title: "How can I send a gift from a story?",
        content: `<p>
            Tap on someone’s Story.<br>
            Tap the gift icon on the right side of your screen<br>
            Tap a gift to send someone a gift!<br>
            Some gifts are free. Get coins to send premium gifts.</p>`,
        categoryId: "features"
    },
    {
        id: "204",
        title: "How can I send a gift from a profile?",
        content: `<p>
            Tap on someone’s profile photo.<br>
            Tap the gift icon below the profile image<br>
            Tap a gift to send someone a gift!<br>
            Some gifts are free. Get coins to send premium gifts.</p>`,
        categoryId: "features"
    },
    {
        id: "205",
        title: "How can I send a gift from a video chat?",
        content: `<p>
            Tap the gift icon on the left side of your screen<br>
            Tap a gift to send someone a gift!<br>
            Some gifts are free. Get coins to send premium gifts.</p>`,
        categoryId: "features"
    },

    // video_chat
    {
        id: "301",
        title: "How can I have a free random chat?",
        content: `<p>
            Tap match<br>
            Tap Start Video Chatting to find people to random chat with.</p>`,
        categoryId: "video_chat"
    },
    {
        id: "302",
        title: "How can I make a direct video chat?",
        content: `<p>
            Tap on a user’s Story, profile photo or Chats on the navigation<br>
            Tap the Video chat Icon<br>
            Tap continue<br>
            If you need more coins, you can buy more coins.<br>
            Or send your invite link to friends to get free coins.</p>`,
        categoryId: "video_chat"
    },
    {
        id: "303",
        title: "How can I find my chats, messages, and missed chats?",
        content: `<p>
            Tap Chats at the bottom of your screen<br>
            You can tap All, Matched, or Missed to see all your connections.</p>`,
        categoryId: "video_chat"
    },

    // stories
    {
        id: "401",
        title: "How do I create a story?",
        content: `<p>
            Tap Match at the bottom of your screen or Tap the red plus sign under your profile’s Stories section.<br>
            On the top left, under your profile photo, Tap Story.<br>
            Tap OK to give HIYAK access to your camera.<br>
            Tap OK to give HIYAK access to your microphone.<br>
            Tap the purple button, and wait 3 seconds to record.<br>
            Create a caption<br>
            Tap the purple +Story button to share<br>
            Tap the x at the top right of your screen to reject and re-record.</p>`,
        categoryId: "stories"
    },
    {
        id: "402",
        title: "How to turn sound off of my story?",
        content: `<p>
            Record your Story<br>
            Before you post, Tap the microphone on the right site to turn the sound on or off.</p>`,
        categoryId: "stories"
    },
    {
        id: "403",
        title: "How can I delete my story?",
        content: `<p>
            Tap on your profile photo<br>
            Scroll down to stories<br>
            Tap the Story you want to delete<br>
            Tap the garbage can to the right.</p>`,
        categoryId: "stories"
    },
    {
        id: "404",
        title: "How can I add a caption to my Story?",
        content: `<p>
            Tap your profile photo<br>
            Tap the pencil icon to the right<br>
            Tap on the “Write Something” Box<br>
            Type in your caption<br>
            Tap the purple story button.</p>`,
        categoryId: "stories"
    },
    {
        id: "405",
        title: "How can I edit a story?",
        content: `<p>
            Tap your profile photo<br>
            Tap the pencil icon<br>
            Tap the Trim icon<br>
            Tap the left side of the slider until it turns red, hold your finger down and slide to where you want to make the edit. Do the same thing for the right side of the video editor.<br>
            Tap the purple story button to re-load.</p>`,
        categoryId: "stories"
    },
    {
        id: "406",
        title: "How do I follow stories?",
        content: `<p>
            Tap the stories button at the bottom left.<br>
            Swipe up to see more stories</p>`,
        categoryId: "stories"
    },
    {
        id: "407",
        title: "How to view a specific person’s stories?",
        content: `<p>
            Tap the bio photo to see a user’s stories.<br>
            Tap a story and swipe up to see all their accounts.</p>`,
        categoryId: "stories"
    },

    // coins
    {
        id: "501",
        title: "What are coins for?",
        content: `<p>You can buy exclusive gifts, set premium search filters, and make direct video calls.</p>`,
        categoryId: "coins"
    },
    {
        id: "502",
        title: "Why aren’t my free coins showing up in my account?",
        content: `<p>It can take up to 36 hours for us to load the coins into your account. They’ll be there soon!</p>`,
        categoryId: "coins"
    },
    {
        id: "503",
        title: "How can I see how many coins I have?",
        content: `<p>
            Tap the bio photo to see a user’s stories.<br>
            Tap a story and swipe up to see all their accounts.</p>`,
        categoryId: "coins"
    },
    {
        id: "504",
        title: "How do I buy more coins?",
        content: `<p>
            If you’re on the stories screen, tap the Match icon at the center bottom of your screen.<br>
            Tap the coin icon at the top of your screen to buy coins.</p>
            <p>Or Tap on your profile and tap the Coins menu.</p>`,
        categoryId: "coins"
    },
    {
        id: "505",
        title: "Where is my invite link for HIYAK so I can get free coins?",
        content: `<p>
            First, Tap on your profile image or the chat icon at the bottom of your screen.<br>
            Tap the coin icon.<br>
            Tap the coin icon at the top top of your screen.<br>
            Then Tap the purple “Share My Link” button to copy the link, or choose the social icon to share your link.</p>
            <p>The more, the merrier. Share with your community and get free coins!</p>`,
        categoryId: "coins"
    },
    {
        id: "506",
        title: "How can I pay for HIYAK?",
        content: `<p>You can sign up and purchase coins through your account at iTunes or Google Play.</p>`,
        categoryId: "coins"
    },

    // profiles
    {
        id: "601",
        title: "How do I edit my profile?",
        content: `<p>
            Tap your bio photo.<br>
            Tap Edit Profile<br>
            Edit here</p>`,
        categoryId: "profiles"
    },
    {
        id: "602",
        title: "How do I change my bio photo?",
        content: `<p>
            Tap the Match Icon at the bottom of your screen.<br>
            Tap the bio image at the top left of your screen.<br>
            Tap the Edit Profile button under your name.<br>
            Tap the Camera Icon and choose Camera to take a new photo.<br>
            Or<br>
            Tap Photos to choose a photo.<br>
            Tap the arrow to accept<br>
            Tap the X to reject and choose a new photo.</p>`,
        categoryId: "profiles"
    },

    // others
    {
        id: "701",
        title: "How do I block someone?",
        content: `<p>Tap on a user’s profile. Tap the three white dots on the top right of your screen, and Tap “Block.”</p>`,
        categoryId: "others"
    },
    {
        id: "702",
        title: "How do I report someone?",
        content: `<p>Tap on the user’s profile. Tap the three white dots on the top right of your screen, and Tap “Report.”</p>`,
        categoryId: "others"
    },
    {
        id: "703",
        title: "How can I submit a feature request?",
        content: `<p>
            Tap your profile photo<br>
            Tap the settings gear<br>
            Tap Feature Request<br>
            Tap the Plus Sign icon<br>
            Type your request<br>
            Tap submit</p>`,
        categoryId: "others"
    },
    {
        id: "704",
        title: "How can I see who likes me?",
        content: `<p>
            Tap your profile photo<br>
            Tap Likes<br>
            You’ll see your likes here.</p>`,
        categoryId: "others"
    },
    {
        id: "705",
        title: "How can I see my gifts?",
        content: `<p>
            Tap your profile photo<br>
            Tap Gifts<br>
            You’ll see your gifts here.</p>`,
        categoryId: "others"
    },
    {
        id: "706",
        title: "Can I look for a specific person on HIYAK?",
        content: `<p>
            Not yet. You can tap Chats at the bottom of your screen.<br>
            Here you can see all your matches, chats, and missed video chats.</p>`,
        categoryId: "others"
    },

]