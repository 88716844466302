import React, {useEffect, useRef} from "react";
import "./TextField.css"

export interface TextFieldProps {
    rows: number;
    value: string;
    placeholder: string;
    className?: string;
    id?: string;
    onChange: (value: string) => void;
}

function TextField(props: TextFieldProps) {
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        resize()
    })

    const resize = () => {
        // @ts-ignore
        textareaRef.current.style.height = "auto";
        // @ts-ignore
        textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        props.onChange(e.currentTarget.value)
    }

    return (
        <textarea ref={textareaRef}
                  rows={props.rows}
                  value={props.value}
                  onChange={handleChange}
                  placeholder={props.placeholder}
                  className={props.className}
                  id={props.id}
        />
    )
}

export default TextField;