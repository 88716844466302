import { StaticHTMLData } from "../model/staticHTMLData";

export const privacy: StaticHTMLData = {
    id: "1",
    title: "Privacy Policy",
    content: `<div class="container">

    <p><strong>LAST UPDATED: JANUARY 07, 2021</strong></p>

    <p>This is the Privacy Policy of HIYAK Video Chat App (“HIYAK”, “us”, “we”, “Service” or “our”) and is incorporated
        into and is subject to our Terms of Use. In this Privacy Policy, we refer to our products and services as the
        “Service.” Please read on to learn more about our data handling practices. We respect your privacy and want you
        to understand how we collect, use, and share data about you. This Privacy Policy covers our data collection
        practices and describes your rights to access, correct, or restrict our use of your personal data; in plain
        language, keeping legal and technical jargon to a minimum. Your privacy is at the core of the way we design and
        build our Services and products you know and love so that you can fully trust them and focus on building
        meaningful connections. Unless we link to a different policy or state otherwise, this Privacy Policy applies
        when you visit or use the Service. Your use of the Service signifies that you agree with the terms of this
        Privacy Policy. If you do not agree with the terms of this Privacy Policy, do not use the Service.</p>

    <h3>Table of Contents</h3>

    <ol>
        <li>Controller</li>
        <li>What Data We Get</li>
        <li>How We Get Data About You</li>
        <li>What We Use Your Data For</li>
        <li>How We Share Information</li>
        <li>Your Rights</li>
        <li>Data Retention</li>
        <li>Data Protection and Security</li>
        <li>Children’s Privacy</li>
        <li>International Transfer Policy</li>
        <li>Privacy Policy Changes</li>
        <li>Contact Us</li>
    </ol>

    <h3>1. Controller</h3>

    <ul>
        <li><strong><u>Controller:&nbsp;</u></strong>The controller of data processing is HIYAK. You can contact us via
            e-mail under the Email Address.</li>
        <li><strong><u>Data Protection Officer:&nbsp;</u></strong>Our data protection officer can be contacted under the
            Email Address. Should you have any questions regarding the processing of your personal data, please do not
            hesitate to contact him/her.</li>
    </ul>

    <h3>2. What Data We Collect</h3>

    <p>This Privacy Policy applies to websites, apps, and other services operated by us. We process personal data that
        you as a user of the Products make available to us, for example by using our Products and that users provide to
        us (“Data”).We can’t help you develop meaningful connections without some data about you, such as basic profile
        details and the types of people you’d like to meet. We also collect information generated as you use our
        services, for example, access logs, as well as information from third parties, like when you access our services
        through a social media account. We also collect some data automatically, like information about your device and
        what parts of our Services you interact with or spend time using.</p>

    <h4>2.1 Data You Provide to Us</h4>

    <ul>
        <li><strong>Account Data:&nbsp;</strong>In order to use certain features (registering for an account), you need
            to create a user account. When you create or update your account, we collect and store the data you provide,
            like your name, gender, and date of birth, and assign you a unique identifying number (“Account Data”).</li>
        <li><strong>Profile Data:&nbsp;</strong>You can also choose to provide profile information like a photo, name,
            languages you speech, interests, sexual interests, or other data. Your Profile Data will be publicly
            viewable by others unless you delete your account. To add certain content, like pictures or videos, you may
            allow us to access your camera or photo album. Some of the information you choose to provide us may be
            considered “special” or “sensitive” in a certain jurisdiction, for example, sexual orientation. By choosing
            to provide this information, you consent to our processing of that information.</li>
        <li><strong>Usage Data:&nbsp;</strong>When you use our Services and features, we collect certain data including:
            follow information, photos, videos, gifts, and other user data. Of course, we also process your chats with
            other users as well as the content you publish, as part of the operation of the Services.</li>
        <li><strong>Shared Content:&nbsp;</strong>Parts of the Services let you interact with other users or share
            content publicly, including by gifts you receive, videos you post and the data you provided in your profile.
            Such shared content may be publicly viewable by others depending on where it is posted.</li>
        <li><strong>Promotions:&nbsp;</strong>When you choose to participate in our promotions, events, or contests, we
            collect the information that you use to register or enter. If you won a prize, we would collect the
            financial information to reward you.</li>
        <li><strong>Data About Your Accounts on Other Services:&nbsp;</strong>We may obtain certain information through
            your social media or other online accounts if they are connected to your account in our Services. If you
            login to our Services via Facebook or another third-party platform, we ask for your permission to access
            certain information about that other account. For example, depending on the platform or service we may
            collect your name, profile picture, gender, and birthday.<br>Those platforms and services make information
            available to us through their APIs. The information we receive depends on what information you (via your
            privacy settings) or the platform or service decide to give us.<br>If you access or use our Services through
            a third-party platform or service, or click on any third-party links, the collection, use, and sharing of
            your data will also be subject to the privacy policies and other agreements of that third party.</li>
        <li><strong>Data About Paid Service:&nbsp;</strong>All our paid service is through iOS or Android platforms. We
            only receive a receipt of your transaction provided by these platforms. For security reasons, we do not
            collect or store sensitive cardholder data, such as full credit card numbers or card authentication data.
        </li>
        <li><strong>Communications and Support:&nbsp;</strong>If you contact us for support or to report a problem or
            concern (regardless of whether you have created an account), we collect and store your contact information,
            messages, and other data about you such as your name, email address, location, operating system, IP address,
            and any other data you provide or that we collect through automated means (which we cover below). We use
            this data to respond to you and research your question or concern, in accordance with this Privacy Policy.
        </li>
        <li><strong>Sensitive Information:&nbsp;</strong>Please be careful about posting sensitive details about
            yourself on your Profile. While you may voluntarily provide this information to us when you create your
            Profile, including your sexual preferences, there is no requirement to do so. Please remember that
            photographs or any video clips that you post on our Services may reveal these kinds of sensitive personal
            data. Where you do upload and choose to tell us sensitive data about yourself, you are explicitly consenting
            to our processing your information and making this public to other users.</li>
    </ul>

    <h4>2.2. Data From Others</h4>

    <p>In addition to the information you provide us directly, we receive information about you from others or by
        automated means, including:</p>

    <ul>
        <li><strong>System Data:&nbsp;</strong>Technical data about your computer or device, like your IP address,
            device type, operating system type and version, unique device identifiers, device language &amp; region,
            browser, browser language, and other systems data, and platform types (“System Data”).</li>
        <li><strong>Usage Data:&nbsp;</strong>We collect information about your activity on our Services, for instance
            how you use them (e.g., date and time you logged in, features you’ve been using, search preferences, clicks,
            and pages which have been shown to you) and how you interact with other users (e.g., users, you connect and
            interact with, time and date of your exchanges, number of content you post).<ul>
                <li><strong>Device Information:&nbsp;</strong>We collect information from and about the device(s) you
                    use to access our Services, including:<br>hardware and software information such as IP address,
                    device type, device-specific and apps settings and characteristics, app crashes, advertising IDs
                    (such as Google’s AAID and Apple’s IDFA, both of which are randomly generated numbers that you can
                    reset by going into your device’ settings), browser type, version and language, operating system,
                    time zones, language, region, identifiers associated with cookies or other technologies that may
                    uniquely identify your device or browser.</li>
            </ul>
        </li>
    </ul>

    <h3>3. How We Get Data About You</h3>

    <p>We use analytics services to gather the data listed below. The main reason we use your information is to deliver
        and improve our Services. Additionally, we use your info to help keep you safe. Read on for a more detailed
        explanation of the various reasons we use your information, together with practical examples.</p>



    <ul>
        <li><strong>3rd Party Usage Analytics Tools:&nbsp;</strong>We use tools provided by Google Inc (1600
            Amphitheater Parkway, Mountain View, CA 94043, USA, “Google”) in our Services for usage analytics
            purposes.&nbsp;<a href="https://www.firebase.com/terms/privacy-policy.html">Firebase</a>&nbsp;operated by
            Google is used for usage analytics.</li>
        <li><strong>Crash Detection Tools:&nbsp;</strong>We use&nbsp;<a
                href="https://docs.fabric.io/android/fabric/data-privacy.html"></a><a
                href="https://www.firebase.com/terms/privacy-policy.html">Firebase</a>&nbsp;tools owned by Google for
            crash detection. It facilitates the maintenance and improvement of the Services. All your crash data is
            collected anonymously.</li>
    </ul>



    <h3>4. What We Use Your Data For</h3>



    <p>The main reason we use your information is to deliver and improve our Services. Additionally, we use your info to
        help keep you safe. Read on for a more detailed explanation of the various reasons we use your information,
        together with practical examples.To administer your account and provide our Services to you</p>



    <ul>
        <li>Create and manage your account</li>
        <li>Provide you with customer support and respond to your requests</li>
        <li>Complete your transactions</li>
        <li>Communicate with you about our Services, including order management and billing</li>
    </ul>



    <p>To help you connect with other users</p>



    <ul>
        <li>Analyze your profile and that of other users to recommend meaningful connections</li>
        <li>Show users’ profiles to one another</li>
    </ul>



    <p>To improve our Services and develop new ones</p>



    <ul>
        <li>Administer focus groups and surveys</li>
        <li>Conduct research and analysis of users’ behavior to improve our Services and content (for instance, we may
            decide to change the look and feel or even substantially modify a given feature based on users’ behavior)
        </li>
        <li>Develop new features and services (for example, we may decide to build a new interests-based feature further
            to requests received from users).</li>
    </ul>



    <p>To prevent, detect and fight fraud or other illegal or unauthorized activities</p>



    <ul>
        <li>Address ongoing or alleged misbehavior on and off-platform</li>
        <li>Perform data analysis to better understand and design countermeasures against these activities</li>
        <li>Retain data related to fraudulent activities to prevent recurrences</li>
    </ul>



    <p>To ensure legal compliance</p>



    <ul>
        <li>Comply with legal requirements</li>
        <li>Assist law enforcement</li>
        <li>Enforce or exercise our rights, for example, our Terms</li>
    </ul>



    <p>To process your information as described above, we rely on the following legal bases:</p>



    <p><strong>Provide our service to you:&nbsp;</strong>Most of the time, the reason we process your information is to
        perform the contract that you have with us. For instance, as you go about using our service to build meaningful
        connections, we use your information to maintain your account and your profile, to make it viewable to other
        users and recommend other users to you.<br><strong>Marketing:</strong> With your explicit consent, we will send
        you marketing push messages.<br><strong>Consent:</strong>&nbsp;From time to time, we may ask for your consent to
        use your information for certain specific reasons. You may withdraw your consent at any time by contacting us at
        the address provided at the end of this Privacy Policy.</p>



    <h3>5. How We Share Information</h3>



    <h4>5.1 With Other Users:</h4>



    <p>You share information with other users when you voluntarily disclose information on the service (including your
        public profile). Please be careful with your information and make sure that the content you share is the stuff
        that you’re comfortable being publically viewable since neither you nor we can control what others do with your
        information once you share it.</p>



    <h4>5.2 With Our Service Providers and Partners:</h4>



    <p>We use third parties to help us operate and improve our Services. These third parties assist us with various
        tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment
        processing, and security operations. These service providers may access your personal data and are required to
        use it solely as we direct, to provide our requested service.</p>



    <p>We follow a strict vetting process prior to engaging any service provider or working with any partner. All of our
        service providers and partners must agree to strict confidentiality obligations.</p>



    <h4>5.3 When Required by Law:</h4>



    <p>We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court
        order, subpoena or search warrant, government/law enforcement investigation or other legal requirements; (ii) to
        assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the
        safety of any person.</p>



    <h4>5.4 To Enforce Legal Rights:</h4>



    <p>We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit;
        (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other
        interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other
        action regarding illegal activity, suspected fraud or other wrongdoing.</p>



    <h4>5.5 Monitor:</h4>



    <p>Moderators to monitor activity on the site/apps and approve content. Fraud prevention and anti-spam providers to
        protect the service from criminal activity.</p>



    <h4>5.6 Change of Control:</h4>



    <p>In the event that our Services or any of its affiliates undergoes a business transition or change of ownership,
        such as a merger, acquisition by another company, reorganization, or sale of all or a portion of its assets, or
        in the event of insolvency or administration, we may be required to disclose your personal data.</p>



    <h3>6. Your Rights</h3>



    <p>You have certain rights around the use of your data, including the ability to opt-out of cookies, and the
        collection of your data by certain analytics providers. You can update or terminate your account from within our
        Services, and can also contact us for individual rights requests about your personal data. We want you to be in
        control of your information, so we have provided you with the following tools:</p>



    <ul>
        <li><strong>Access / Update tools in the service:&nbsp;</strong>Tools and account settings that help you to
            access, rectify or delete information that you provided to us and that’s associated with your account
            directly within the service.</li>
        <li><strong>Device permissions:&nbsp;</strong>Mobile platforms have permission systems for specific types of
            device data and notifications, such as phone book and location services as well as push notifications. You
            can change your settings on your device to either consent or oppose the collection of the corresponding
            information or the display of the corresponding notifications. Of course, if you do that, certain services
            may lose full functionality.</li>
        <li><strong>Deletion:&nbsp;</strong>You can delete your account by using the corresponding functionality
            directly on the service.</li>
    </ul>



    <p>We want you to be aware of your privacy rights. Here are a few key points to remember:</p>



    <ul>
        <li><strong>Reviewing your information:</strong>&nbsp;Applicable privacy laws may give you the right to review
            the personal information we keep about you (depending on the jurisdiction, this may be called right of
            access, right of portability or variations of those terms). You can request a copy of your personal
            information by sending us an email at&nbsp;hello@hiyak.com.</li>
        <li><strong>Updating your information:&nbsp;</strong>If you believe that the information we hold about you is
            inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion or
            object to its processing, please contact us via&nbsp;hello@hiyak.com.</li>
    </ul>



    <p>For your protection and the protection of all of our users, we may ask you to provide proof of identity before we
        can answer the above requests.</p>



    <p>Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may
        infringe on trade secrets or intellectual property or the privacy of another user. If you wish to receive
        information relating to another user, such as a copy of any messages you received from him or her through our
        service, the other user will have to contact our Privacy Officer to provide their written consent before the
        information is released.</p>



    <p>Also, we may not be able to accommodate certain requests to object to the processing of personal information,
        notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot
        provide our service if we do not have your date of birth.</p>



    <ul>
        <li><strong>Uninstall:&nbsp;</strong>You can stop all information collected by an app by uninstalling it using
            the standard uninstall process for your device. If you uninstall the app from your mobile device, the unique
            identifier associated with your device will continue to be stored. If you re-install the application on the
            same mobile device, we will be able to re-associate this identifier to your previous transactions and
            activities.</li>
        <li><strong>Accountability:&nbsp;</strong>In certain countries, including in the European Union, you have a
            right to lodge a complaint with the appropriate data protection authority if you have concerns about how we
            process your personal information. The data protection authority you can lodge a complaint with notably may
            be that of your habitual residence, where you work or where we are established.</li>
    </ul>



    <p>If you want to exercise any of your rights listed above please visit our feedback form or email us
        at&nbsp;hello@hiyak.com.</p>



    <h3>7. Data Retention</h3>



    <p>We keep your personal information only as long as we need it for legitimate business purposes and as permitted by
        applicable law. To protect the safety and security of our users on and off our Services, we implement a safety
        retention window of three months following account deletion unless there is a technical difficulty. During this
        period, some of your account information will be retained although the account will of course not be visible on
        the Services anymore. In practice, we delete or anonymize your information upon the deletion of your account
        (following the safety retention window) or after two years of continuous inactivity. An account is considered
        inactive when the Application of the Member has not established contact with HIYAK’s servers, and the Member has
        not used the Application. In any event, the Account of a Premium Member is not considered inactive. Unless:</p>



    <ul>
        <li>we must keep it to comply with applicable law (for instance, some “traffic data” is kept for one year to
            comply with statutory data retention obligations) as well as to prevent bot systems that are abusing the
            system;</li>
        <li>we must keep it to evidence our compliance with applicable law (for instance, records of consents to our
            Terms, Privacy Policy, and other similar consents are kept for five years);</li>
        <li>there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is
            resolved; or</li>
        <li>the information must be kept for our legitimate business interests, such as fraud prevention and enhancing
            users’ safety and security. For example, information may need to be kept to prevent a user who was banned
            for unsafe behavior or security incidents from opening a new account.</li>
    </ul>



    <p>Correspondence with our Customer Support team will be retained for longer to allow us to deal with any subsequent
        queries you may have but will usually be deleted within three years if there are no queries.</p>



    <p>Anonymized information about activity on our Services is retained for longer periods of time for statistical and
        product research purposes but this is not attributable to an individual once the profile has been deleted.</p>



    <p>Keep in mind that even though our systems are designed to carry out data deletion processes according to the
        above guidelines, we cannot promise that all data will be deleted within a specific time frame due to technical
        constraints.</p>



    <h3>8. Data Protection and Security</h3>



    <p>We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal
        information. As with all technology companies, although we take steps to secure your information, we do not
        promise, and you should not expect, that your personal information will always remain secure. Your account is an
        important part of our security system, and it is your responsibility to protect it. You should not share your
        account with any third party, and if you believe your account has been compromised, you should
        contact&nbsp;hello@hiyak.com with any concerns.</p>



    <p>We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information
        collection, storage, and processing practices to update our physical, technical and organizational security
        measures.</p>



    <p>We may suspend your use of all or part of the Services without notice if we suspect or detect any breach of
        security. If you believe that your account or information is no longer secure, please notify us immediately. We
        are not responsible for virus protection outside our service network. We are also not responsible for the damage
        or destruction of user data. We will pursue every detected attack by hackers, etc. under civil law and, if
        necessary, by the prosecution.</p>



    <p>In case of a breach, we will notify; the EU representatives, our DPO and our users who were or potentially were
        affected by the breach within 72 hours of the attack. We will announce it on our webpage.</p>



    <h3>9. Children’s Privacy</h3>



    <p>Our Services are restricted to users who are 18 years of age or older. We do not permit users under the age of 18
        on our platform and we do not knowingly collect personal information from anyone under the age of 18. If you
        suspect that a user is under the age of 18, please use the reporting mechanism available through the service. If
        we become aware that a child, minor or anyone under the age of 18 has registered with us and provided us with
        personal data, we will take steps to terminate that person’s registration and delete their profile information
        from our Services. If we do delete a Profile because you violated our no children rule, we may retain basic
        account and profile information to ensure that you do not try to get around our rules by creating a new profile.
    </p>



    <h3>10. International Transfer Policy</h3>



    <p>In order to provide the Services to you, we must transfer your data to our servers and process it there. By
        visiting or using our Services, you consent to the storage of your data on servers. If you are using the
        Services, you consent to the transfer, storage, and processing of your data. Specifically, personal data
        collected in Switzerland and the European Economic Area (“EEA”) is transferred and stored outside those areas.
    </p>



    <p>That data is also processed outside of Switzerland and the EEA by our HIYAK companies, or our service providers,
        including to process transactions, facilitate payments and provide support services as described in Section 4
        &amp; 5. We have entered into data processing agreements with our service providers that restrict and regulate
        their processing of your data on our behalf. By submitting your data or using our Services, you consent to this
        transfer, storage, and processing by HIYAK and its processors. We operate a global network of servers including
        in Germany, Netherlands, Turkey, and Canada. We ensure that the data is adequately protected by ensuring that
        valid, legal mechanisms are in place. If you want more information relating to the nature of the safeguards we
        have in place please email&nbsp;hello@hiyak.com.</p>



    <h3>11. Privacy Policy Changes</h3>



    <p>we’re always looking for new and innovative ways to help you build meaningful connections, this policy may change
        over time. We will notify you before any material changes take effect so that you have time to review the
        changes.</p>



    <h3>12. Contact Us</h3>



    <p>If you have any questions regarding this privacy statement, the processing of your personal data, you can contact
        us directly. We are also available to you in the case of requests for information, requests or complaints:</p>



    <p><strong>Address:</strong>&nbsp;HIYAK Global Limited, Flat B, 4/F., Unionway Commercial Centre, 283 Queen’s Road
        Central, Sheung Wan, Hong Kong<br><strong>E-MAIL:</strong>&nbsp;<a
            href="mailto:hello@hiyak.com">hello@hiyak.com</a></p>
</div>
        `
}
