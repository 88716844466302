import React from "react";
import { Link, useLocation } from "react-router-dom";
import FeatureList from "../../../components/FeatureList/FeatureList";
import NavigationBar from "../../../components/NavigationBar/NavigationBar";
import CircleButton from "../../../components/CircleButton/CircleButton";

import "./FeatureListView.css"

import { User } from "../../../model/user";
import { Feature } from "../../../model/feature";

export interface FeatureListPageProps {
    user: User;
    newFeatures?: Feature[];
    topFeatures?: Feature[];
    finishedFeatures?: Feature[];
    onUpFeature: (id: string) => void;
    onDownFeature: (id: string) => void;
    onGetNextFeatures: (category: string) => void;
}

enum FeatureTab {
    New = "/features/tab/new",
    Top = "/features/tab/top",
    Finished = "/features/tab/finished"
}

function FeatureListView(props: FeatureListPageProps) {
    const location = useLocation()

    let features: Feature[] = []

    if (location.pathname === FeatureTab.New) {
        if (props.newFeatures !== undefined) {
            features = props.newFeatures;
        }
    } else if (location.pathname === FeatureTab.Top) {
        if (props.topFeatures !== undefined) {
            features = props.topFeatures;
        }
    } else if (location.pathname === FeatureTab.Finished) {
        if (props.finishedFeatures !== undefined) {
            features = props.finishedFeatures;
        }
    }

    return (
        <div className="feature-list">
            <NavigationBar />

            <FeatureList
                features={features}
                user={props.user}
                onLike={(featureId: string) => props.onUpFeature(featureId)}
                onDislike={(featureId: string) => props.onDownFeature(featureId)}
            />

            <div className="fixed-button">
                <Link to={{
                    pathname: "/features/create",
                    search: location.search
                }}>
                    <CircleButton />
                </Link>
            </div>
        </div>
    );
}

export default FeatureListView;
