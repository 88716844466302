import React from "react";
import {Faq, FaqCategory} from "../../../model/faq";

import FaqListView from "../FaqList/FaqListView";

interface faqCategoryCellProps {
    category: FaqCategory;
    faqs: Faq[];
}

function FaqCategoryCell(props: faqCategoryCellProps) {

    return (
        <div>
            <h2>{props.category.name}</h2>
            <FaqListView faqs={props.faqs}/>
        </div>
    )
}

export default FaqCategoryCell