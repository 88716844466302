import { StaticHTMLData } from "../model/staticHTMLData";

export const terms: StaticHTMLData = {
    id: "1",
    title: "TERMS OF LICENSE AND USE",
    content: `<div class="container">
    <p><strong>LAST UPDATED: JANUARY 07, 2021</strong></p>
    <p>IMPORTANT LEGAL NOTICE.</p>
    <p>YOU MUST BE AT LEAST 18 YEARS OLD AND THE AGE OF MAJORITY AND LEGAL CONSENT IN THE JURISDICTION IN WHICH YOU LIVE
        OR RESIDE TO AGREE TO THESE TERMS.</p>

    <p>This agreement (“Agreement”) is a contract between you and (“HIYAK”, “Service”, “us” “our” and “we”) and governs
        your use of the HIYAK mobile application and website services (the “Service”). By using the Services, you
        represent and warrant that you are at least 18 years old and the age of majority and legal consent in the
        jurisdiction in which you live or reside, and you agree to be bound by the following Terms of Service (the
        “Terms of Service”), whether or not you register as a member (“Member”). We may modify this Agreement
        occasionally, and such modification shall be effective upon posting by us on the mobile application and website.
        We may update you on our changes through your email or notify you through the mobile app or website. You agree
        to be bound by any changes to this Agreement when you use the Service after any such modification is posted.
        This Agreement includes Service’s policy for acceptable use and content posted on the mobile application and
        website, your rights, obligations, and restrictions regarding your use of the mobile application and website.
    </p>

    <p>If you are under the age of 18, you may not download or use our Services. We do not knowingly collect or maintain
        information from children under age 18. If you are under the age of 18, you must get your parent or guardian to
        read and agree to our Terms of Service and Privacy Policy. Please, choose carefully the information you post on
        our Services and the information you provide to other Members. Your Service profile may not include the
        following items: nude, violent or offensive images or texts. The information posted by other Service Members may
        contain inaccurate, inappropriate or offensive materials, products or services and Service assumes no
        responsibility or liability for this material.</p>

    <p>We reserve the right, in its sole discretion, to reject, refuse to post or remove any posts (including email) by
        you, or to restrict, suspend, or terminate your access to all or any part of the mobile application and website
        and/or Services at any time, for any or no reason, with or without prior notice, and without liability.</p>

    <h3>1. General</h3>

    <p>We reserve the right to change these Terms of Service at any time. We will notify you within the app. You are
        responsible for keeping yourself appraised of any such changes if you dismiss these notifications. If you object
        to any Terms of Service or any subsequent changes or you become dissatisfied with our Services in any way, your
        only option is to delete your account. Your continued use of our Services after we post revised Terms of Service
        means that you agree to the revisions. You must be at least 18 years old to download our Services. By requesting
        to use, or using our Services, you represent and warrant that you are not required to register as a sex offender
        with any government entity.</p>

    <p>Our Services are free, but your Internet provider’s or mobile operator’s fees and rates may still apply. However,
        we do have in-app purchases that you can buy if you want to use some of our extra features.</p>

    <h3>2. Your Account</h3>

    <p>You can also choose to provide profile information like a photo, gender, birthday, name, sexual interests, or
        other data. You can read more on our Privacy Policy, which has detailed information on what information is used.
    </p>

    <h3>3. Information About How You Use the Services</h3>

    <p>We may collect information about your actions on the Services. This may include information such as the pages and
        profiles you view, how you use the Services, your coin balance, and purchases you make through the Services. It
        can also include the various functions and features that you use, such as the connections you make, other
        friends or contacts you invite to the Services and profile searches you perform, how often you log in and with
        what/ which platform you log in from, your IP address and how many profiles you have created, or your use of our
        applications. Read more on our Privacy Policy.</p>

    <h3>4. Content Posted on the Mobile Application</h3>

    <ol>
        <li>You understand and agree that our Services may review and delete any content, photo, profile, video, sound
            (collectively, “Content”) that is in the sole judgment of us.</li>
        <li>You are solely responsible for the Content that you publish or display (hereinafter, “post”) on the Service
            or any material or information that you transmit to other Members.</li>
        <li>By posting any Content to the public areas of the mobile application and website, you hereby grant us the
            non-exclusive, fully paid, worldwide license to use, reproduce, publicly perform and display such Content on
            the mobile application and website, including without limitation distributing part or all of the mobile
            application and website in any media formats and through any media channels. You represent and warrant that:
            (i) you own the Content posted by you on the mobile application and website or otherwise have the right to
            grant the license set forth in this section, and (ii) your Content does not violate the privacy rights,
            publicity rights, copyright rights, or other intellectual property rights of any person. You agree to pay
            all royalties and fees owing to any person by reason of any Content you post on the mobile application and
            website.</li>

        <li>The following is a partial list of the kind of Content that is illegal or prohibited on the mobile
            application and website. We reserve the right to investigate and take appropriate legal action in its sole
            discretion against anyone who violates this provision, including without limitation, removing the offending
            communication from the Service and terminating the membership of such violators. <br>
            Prohibited Content includes Content that:

            <ul>
                <li>
                    is patently offensive and promotes racism, sexism, bigotry, hatred or physical harm of any kind
                    against any group or individual, any content that is obscene, pornographic or otherwise may offend
                    human dignity.
                </li>
                <li>Harasses or advocates harassment of another person or contains language which could be deemed
                    offensive or is likely to harass, upset, embarrass, alarm or annoy any other person;</li>
                <li>involves the transmission of “junk mail”, “chain letters,” or unsolicited mass mailing or “spamming”
                    or pyramid scams;</li>
                <li>promotes information that you know is false or misleading or promotes illegal activities or conduct
                    that is abusive, threatening, obscene, defamatory or libelous;</li>
                <li>promotes an illegal or unauthorized copy of another person’s copyrighted work, such as providing
                    pirated computer programs or links to them, providing information to circumvent
                    manufacture-installed copy-protect devices, or providing pirated music or links to pirated music
                    files;</li>
                <li>contains restricted or password-only access pages or hidden pages or images (those not linked to or
                    from another accessible page);</li>
                <li>provides material that exploits people under the age of 18 in a sexual or violent manner, or
                    solicits personal information from anyone under 18;</li>
                <li>provides instructional information about illegal activities such as making or buying illegal
                    weapons, violating someone’s privacy, providing or creating computer viruses;</li>
                <li>solicits passwords or personal identifying information for commercial or unlawful purposes from
                    other users; or</li>
                <li>involves commercial activities and/or sales without our prior written consent such as contests,
                    sweepstakes, barter, advertising, or pyramid schemes.</li>
            </ul>
        </li>

        <li>You must use the Service in a manner consistent with any and all applicable laws and regulations.</li>

        <li>You may not include in your publicly accessible Member profile any telephone numbers, street addresses, last
            names, URLs, usernames from any social platforms or email addresses.</li>

        <li>You may not engage in advertising to or solicitation of, any Member to buy or sell any products or services
            through vice. You may not transmit any chain letters or junk email to other Members. Although we cannot
            monitor the conduct of its Members off the mobile application and website, it is also a violation of these
            rules to use any information obtained from the Service in order to harass, abuse, or harm another person, or
            in order to contact, advertise to, solicit, or sell to any Member without their prior explicit consent. In
            order to protect our Members from such advertising or solicitation, we reserve the right to restrict the
            number of messages which a Member may send to other Members in any period to a number which we deem
            appropriate in its sole discretion.</li>

        <li>You may not attempt to impersonate another user or a person.</li>
    </ol>

    <h3>5. Prohibited Activities</h3>

    <p>We reserve the right to investigate, suspend and/or terminate your account if you have misused the Service or
        behaved in a way we regard as inappropriate or unlawful, including actions or communications that occur off the
        Service but involve users you meet through the Service. The following is a partial list of the type of actions
        that you may not engage in with respect to the Service. You will not:</p>

    <ul>
        <li>impersonate any person or entity.</li>
        <li>solicit money from any users.</li>
        <li>harm, stalk or otherwise harass another.</li>
        <li>impersonate, or misrepresent your relationship with, any person or entity (this includes pretending to be
            minor if you are not)</li>
        <li>upload or transmit any Content that you do not have a right to make available, or that infringes any patent,
            trademark, trade secret, copyright, privacy, or other proprietary rights of any party.</li>
        <li>express or imply that any statements you make are endorsed by us without our specific prior written consent.
        </li>
        <li>use the Service in an illegal manner or to commit an illegal act.</li>
        <li>access the Service in a jurisdiction in which it is illegal or unauthorized.</li>
        <li>ask or use users to conceal the identity, source, or destination of any illegally gained money or products.
        </li>
        <li>use any robot, spider, site search/retrieval application, or other manual or automatic device or process to
            retrieve, index, “data mine”, or in any way reproduce or circumvent the navigational structure or
            presentation of the Service or its contents.</li>
        <li>upload or transmit software viruses or any other harmful computer code, files or programs, or use any data
            mining, robots, or similar data gathering or extraction methods.</li>
        <li>collect usernames and/or email addresses of users by electronic or other means for the purpose of sending
            unsolicited email or unauthorized framing of or linking to the Service.</li>
        <li>interfere with or disrupt the Service or the servers or networks connected to the Service.</li>
        <li>transmit any material that contains software viruses or any other computer code, files or programs designed
            to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications
            equipment.</li>
        <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any information
            transmitted to or through the Service (either directly or indirectly through the use of third-party
            software).</li>
        <li>“frame” or “mirror” any part of the Service, without our prior written authorization.</li>
        <li>use meta tags or code or other devices containing any reference to our Services or the Service (or any
            trademark, trade name, service mark, logo or slogan of our Services) to direct any person to any other
            mobile application and website for any purpose.</li>
        <li>modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble
            any portion of the Service any software used on or for the Service, or cause others to do so.</li>
        <li>post, use, transmit or distribute directly or indirectly (e.g. screen scrape) in any manner or media any
            content or information obtained from the Service other than solely in connection with your use of the
            Service in accordance with this Agreement.</li>
        <li>register accounts or post Content automatically, systematically, or programmatically.</li>
        <li>create unnecessary amounts of accounts. If we determine you have created various accounts to spam,
            misinterpret, or for any other malicious reasons we reserve the right to terminate your account without
            notice.</li>

    </ul>

    <p>We reserve the right to freeze/ban your account if we see that your IP address changes continuously varying
        between countries. You agree that all information that you submit upon creation of your account is accurate and
        truthful and you have the right to post the Content on the Service and grant the license to HIYAK. You
        understand and agree that we may monitor or review any Content you post as part of a Service. We may delete any
        Content, in whole or in part, that in our sole judgment violates this Agreement or may harm the reputation of
        the Service.</p>

    <p>By submitting suggestions or feedback to us regarding our Services, you agree that we may use and share such
        feedback for any purpose without compensating you.</p>

    <p>You agree that we may access, preserve and disclose your account information and Content if required to do so by
        law or in a good faith belief that such access, preservation or disclosure is reasonably necessary, such as to
        (i) comply with legal process; (ii) enforce this Agreement; (iii) respond to claims that any Content violates
        the rights of third parties; (iv) respond to your requests for customer service; or (v) protect the rights,
        property or personal safety of the Company or any other person.</p>

    <h3>6. Your Interactions with Other Users</h3>

    <p>Though we strive to encourage a respectful user experience, it is not responsible for the conduct of any user on
        or off of the Services. You agree to use caution in all interactions with other users, particularly if you
        decide to communicate off the Service or meet in person. You should not provide your financial information (for
        example, your credit card or bank account information), wire or otherwise send money, to other users. You are
        solely responsible for your interactions with other Members. We reserve the right but have no obligation, to
        monitor disputes between you and other Members.
    </p>

    <h3>7. No Spam Policy</h3>

    <p>You may not engage in any activity involving spam on our Services. Violations of this policy may result in
        immediate termination of service and legal action against any spammer. Accordingly, you may not upload, post,
        email, transmit or otherwise make available junk mail, commercial advertisements, or any other form of
        commercial solicitation on our Services. Violations of this policy could subject you or your agents to civil and
        criminal penalties.</p>

    <h3>8. Modifying the Services and Termination</h3>

    <p>We are always striving to improve the Services and bring you the additional functionality that you will find
        useful. This means we may add new product features or enhancements from time to time as well as remove some
        features, and if these actions do not materially affect your rights or obligations, we may not provide you with
        notice before taking them. We may even suspend the Services entirely, in which event we will notify you in
        advance unless extenuating circumstances, such as safety or security concerns, prevent us from doing so. You may
        terminate your account at any time, for any reason. We may terminate your account at any time without notice if
        we believe that you have violated this Agreement.</p>

    <h3>9. Eligibility</h3>

    <p>
        Membership in the Service is void where prohibited. By using the mobile application and website and the Service,
        you represent and warrant that all registration information you submit is truthful and accurate. You represent
        and warrant that you are 18 years of age or older and that your use of the Service shall not violate any
        applicable laws or regulations. Your profile may be deleted without warning if it is found that you are
        misrepresenting your age. Your Membership is solely for your personal use, and you shall not authorize others to
        use your account, including your profile or email address. You are solely responsible for all Content published
        or displayed through your account, and for your interactions with other members.
    </p>

    <h3>10. Virtual Currency</h3>

    <h4>10.1 Virtual Currency and Gifts</h4>

    <p>When you purchase or receive Virtual Currency products such as coins and gifts you do not own them. Coins are
        used
        to buy gifts and to make video calls. You agree that we will have no liability to you based on the exercise of
        our
        rights with respect to Gifts and coins. We reserve the right to verify your identity and eligibility to use your
        virtual currency and gifts.</p>

    <p>You may use Coins to purchase Gifts, unlock advanced filters and make video calls. The price for each Gift will
        be
        displayed at the point of purchase. Gifts constitute a limited license to access a certain feature on the HIYAK
        platform when, and if allowed by us. All sales of Gifts are final, and we do not offer refunds for any purchased
        Gifts. We are not responsible for repairing or replacing Gifts, or providing you with any credit or refund in
        the
        event that we modify, suspend, or terminate the Gift program, or for loss or damage due to any service error, or
        any
        other reason. Purchases of Virtual Currency (Coins) and Gifts may not be sub-licensed and are non-refundable and
        non-transferable, even if they expire or are revoked or discontinued. We may change the purchase price for
        Virtual
        Currency (Coins), or Gifts at any time, as well as the ways you can use Virtual Currency (Coins) and Gifts. We
        reserve the right to revoke or stop issuing Virtual Currency (Coins) and Gifts at any time without notice,
        refund,
        or compensation, and to set expiration dates for Virtual Currency (Coins) and Gifts. Virtual Currency (Coins)
        and
        Gifts may not be redeemed for any sum of money or monetary value. If you delete your account or your account is
        terminated for any reason, you will lose all accumulated and unused Virtual Currency (Coins) and Gifts without
        refund or other compensation. You agree that we will have no liability to you based on the exercise of our
        rights
        with respect to Virtual Currency (Coins) and Gifts.</p>

    <p>Gifts purchased or received by any user do not constitute property and are not transferable.</p>

    <h4>10.2 Payment Method</h4>

    <p>HIYAK’s Paid Services</p>

    <p>HIYAK’s Paid Services may be purchased through your accounts with certain third-parties, such as your Apple
        iTunes
        account or your Google Play account (a “Third Party Account”). If you purchase any Paid Services through a
        Third-Party Account, billing for these Paid Services will appear through your Third-Party Account. You should
        review
        the Third-Party Account’s Terms of Service, which we do not control.</p>

    <h4>10.3 Term/Fees</h4>

    <p>This Agreement shall remain in full force and effect while you use the mobile application and website and/or the
        Service. We may terminate your membership for any reason, at any time, without notice. If we terminate your
        Membership in the Service, you shall not be entitled to a refund of any unused portion of subscription fees, if
        any.
        Even after membership is terminated, this Agreement will remain in effect. Our Services’ Terms of Service and/or
        subscription fees, if any, that were provided to you at registration is subject to change if needed. By using
        the
        Service and becoming a Member, you acknowledge that we reserve the right to charge for the Service and have the
        right to terminate a Member’s Membership, should a Member breach this Agreement or fail to pay for the Service
        if
        necessary, as required by this Agreement. Standard usage charges for SMS, MMS, and other data charges may apply
        and
        may be billed on your wireless service bill or deducted from your prepaid balance.</p>

    <h3>11. Disclaimers</h3>

    <p>We are not responsible for any incorrect or inaccurate Content posted on the mobile application and website or in
        connection with the Service provided, whether caused by users of the mobile application and website, Members or
        by
        any of the equipment or programming associated with or utilized in the Service. We are not responsible for the
        conduct, whether online or offline, of any user of the mobile application and website or Member of the Service.
        We
        assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or
        transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any
        user or Member communication. Our Services is not responsible for any problems or technical malfunction of any
        telephone network or lines, computer online systems, wireless phones or other devices, servers or providers,
        computer equipment, software, failure of any email or players due to technical problems or traffic congestion on
        the
        Internet or at any mobile application and website or combination thereof, including any injury or damage to
        users
        and/or Members or to any person’s computer related to or resulting from participation or downloading materials
        in
        connection with the mobile application and website and/or in connection with the Service. Under no circumstances
        shall Service be responsible for any loss or damage, including personal injury or death, resulting from the use
        of
        the mobile application and website or the Service or from any Content posted on the mobile application and
        website
        or transmitted to Members, or any interactions between users of the mobile application and website, whether
        online
        or offline. The mobile application and website and the Service are provided “AS-IS” and Service expressly
        disclaims
        any warranty of fitness for a particular purpose or non-infringement. Service cannot guarantee and does not
        promise
        any specific results from use of the mobile application and website and/or the Service.</p>

    <h3>12. U.S. Export Controls</h3>

    <p>Software from this mobile application and website (the “Software”) is further subject to United States export
        controls. No Software may be downloaded from the mobile application and website or otherwise exported or
        re-exported
        (i) into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria, or any other country to
        which the U.S. has embargoed goods; or (ii) to anyone on the U.S. Treasury Department’s list of Specially
        Designated
        Nationals or the U.S. Commerce Department’s Table of Deny Orders. By downloading or using the Software, you
        represent and warrant that you are not located in, under the control of, or a national or resident of any such
        country or on any such list.</p>

    <h3>13. Disputes</h3>

    <p>You agree that any dispute relating in any way to these Terms of Service or Service, including without limitation
        your or third-parties’ access to or use of our Services, will be resolved by binding arbitration and not through
        litigation in any court (except in small claims court if the claim is within the court’s jurisdiction and
        proceeds
        on an individual basis). Claims are subject to arbitration, regardless of what they are based on or whether they
        seek legal or equitable remedies. Arbitration applies to any and all such claims or disputes, whether they arose
        in
        the past, may currently exist, or may arise in the future.</p>

    <h3>14. Indemnity</h3>

    <p>You agree to indemnify and hold Service, its subsidiaries, affiliates, officers, agents, and other partners and
        employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, made by
        any
        third party due to or arising out of your use of the Service in violation of this Agreement and/or arising from
        a
        breach of this Agreement and/or any breach of your representations and warranties set forth above.</p>

    <h3>15. No Agency</h3>

    <p>There is no agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship between
        HIYAK and any User of the Service.
    </p>

    <h3>16. Non-commercial Use by Members</h3>

    <p>The mobile application and website is for the personal use of Members only and may not be used in connection with
        any commercial endeavors except those that are specifically endorsed or approved by the management of our
        Services.
        Illegal and/or unauthorized use of the mobile application and website will be investigated. Commercial
        advertisements, affiliate links, and other forms of solicitation may be removed from Member profiles without
        notice
        and may result in termination of membership privileges. Appropriate legal action will be taken by Service for
        any
        illegal or unauthorized use of the mobile application and website.</p>

    <h3>17. Customer Service</h3>

    <p>
        Service provides assistance and guidance through its customer care representatives. When communicating with our
        customer care representatives, you agree not to be abusive, obscene, profane, offensive, sexist, threatening,
        harassing, racially offensive, or not to behave inappropriately. If we feel that your behavior towards any of
        our
        customer care representatives or other employees is at any time threatening or offensive, we reserve the right
        to
        immediately terminate your account.
    </p>

    <h3>18. Disclaimer of Warranties</h3>

    <p>You use HIYAK at your sole risk. We do not guarantee that our Services will always be safe, secure or error-free
        or
        that our Services will always function without disruptions, delays or imperfections. We make no warranty that
        our
        Services will meet your requirements, or HIYAK will be uninterrupted, timely, secure and error-free. We make no
        warranty that the results obtained from us will be accurate or reliable. We make no warranty that the quality of
        products, Services, information, material or purchased features obtained through our Services will meet your
        expectations. Any downloaded or obtained material through our Services is done at your own risk. You will be
        solely
        responsible for any damage to your or others’ devices directly or indirectly from the download of any such
        material.
        Some jurisdiction does not allow the exclusion of such warranties or the limitation or the exclusion of
        liability
        for incidental or consequential damages. Some of the limitations of the warranty may not apply to you. In such
        cases, the warranties and liability will be limited to the fullest extent permitted by applicable law.</p>

    <h3>19. Copyright Policy</h3>

    <p>You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary
        information without obtaining the prior written consent of the owner of such proprietary rights. It is the
        policy of
        our Services to terminate membership privileges of any member who repeatedly infringes copyright upon prompt
        notification to HIYAK by the copyright owner or the copyright owner’s legal agent. Without limiting the
        foregoing,
        if you believe that your work has been copied and posted on our Services in a way that constitutes copyright
        infringement, please provide our Designated Copyright Agent with the following information: (i) an electronic or
        physical signature of the person authorized to act on behalf of the owner of the copyright interest; (ii) a
        description of the copyrighted work that you claim has been infringed; (iii) a description of where the material
        that you claim is infringing is located on our Services; (iv) your address, telephone number, and email address;
        (v)
        a written statement by you that you have a good faith belief that the disputed use is not authorized by the
        copyright owner, its agent, or the law; (vi) a statement by you, made under penalty of perjury, that the above
        information in your notice is accurate and that you are the copyright owner or authorized to act on the
        copyright
        owner’s behalf. Our Services’ Designated Copyright Agent for notice of claims of copyright infringement can be
        reached as follows: hello@hiyak.com.</p>

    <h3>20. Safety and Security</h3>

    <p>We do not currently conduct criminal background screenings on our Members. We reserve the right, however, to
        conduct
        any criminal background check we deem appropriate, at any time and using available public records. By agreeing
        to
        these Terms of Service, you hereby authorize any such check.</p>

    <h3>21. Account Security</h3>

    <p>You are responsible for maintaining the confidentiality of the account you designate during the registration
        process, and you are solely responsible for all activities that occur under your account. You agree to
        immediately
        notify us of any disclosure or unauthorized use of your account or any other breach of security at
        hello@hiyak.com
        and ensure that you log out from your account at the end of each session.
    </p>

    <h3>22. Links</h3>

    <p>The sites linked to by the Service mobile application and website are developed by people over whom we exercise
        no
        control, HIYAK cannot and does not screen the sites. Some people may find these sites objectionable,
        inappropriate,
        or offensive. We cannot guarantee that our Services will not link to unintended or objectionable content and
        assume
        no responsibility for the content of any site linked to by the Service.</p>

    <h3>23. Limitation of Liability</h3>

    <p>In no event shall our Services be liable to you or any third-party for any indirect, consequential, exemplary,
        incidental, special or punitive damages including lost profit damages arising from your use of the mobile
        application and website or the Service even if our Services have been advised of the possibility of such
        damages.</p>

    <p>Services’ liability to you for any cause, whatsoever and regardless of the form of the action, will at all times
        be
        limited to the amount paid, if any, by you to us for the Service during the term of Membership.</p>

    <h3>24. Other</h3>

    <p>This Agreement is accepted upon your use of the mobile application and website and is further affirmed by you
        becoming a Member of the Service. This Agreement constitutes the entire agreement between you and Service
        regarding
        the use of the mobile application and website and/or the Service. The failure of Service to exercise or enforce
        any
        right or provision of this Agreement shall not operate as a waiver of such right or provision. The section
        titles in
        this Agreement are for convenience only and have no legal or contractual effect. Please, contact us with any
        questions regarding this Agreement.</p>
</div>`
}
