import {AppAPI} from "./index";
import {Feature} from "../model/feature";

export const fetchFeatures = async (category: string): Promise<Feature[]> => {
    try {
        const response = await AppAPI.get<Feature[]>("/features/", {
            params: {
                category: category
            }
        })
        return response.data
    } catch (e) {
        throw e.response.data
    }
}

export const voteFeatureById = async (id: string, action: string) => {
    try {
        const response = await AppAPI.put(`/features/${id}?action=${action}`)
        console.log(response)
    } catch (e) {
        console.log(e.response)
        throw e
    }
}

export const createFeature = async (title: string, content: string): Promise<Feature|null> => {
    try {
        const response = await AppAPI.post<Feature>(`/features/`, {
            title: title,
            content: content
        })
        return response.data
    } catch (e) {
        console.log(e.response)
        throw e
    }
}