import React from "react";
import "./TermsView.css";
import DetailsView from "../../../components/DetailsView/DetailsView";
import {terms} from "../../../data/termsData";

function TermsView() {

    const documentTitle = "Terms of Use"
    const contentHtml = terms.content

    return (
        <div className="page">
            <DetailsView documentTitle={documentTitle} html={contentHtml}/>
        </div>
    )

}

export default TermsView;