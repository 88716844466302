import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import _ from "lodash";

import FeatureListView from "./FeatureListView/FeatureListView";
import CreateFeaturePage from "./CreateFeatureView/CreateFeatureView";
import { User } from "../../model/user";
import { Feature } from "../../model/feature";

import { fetchUser } from "../../api/userApi";
import { fetchFeatures, voteFeatureById } from "../../api/featureApi";
import { initToken } from "../../api";

enum VoteAction {
    Up = "up",
    Down = "down"
}

function FeaturesRootView() {

    // states

    const [user, setUser] = useState<User>();
    const [newFeatures, setNewFeatures] = useState<Feature[]>();
    const [topFeatures, setTopFeatures] = useState<Feature[]>();
    const [finishedFeatures, setFinishedFeatures] = useState<Feature[]>();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const idToken = params.get('idToken');

    useEffect(() => {
        document.title = "Feature request";

        if (idToken === null) {
            alert("401: Unauthorized");
        } else {
            initToken(idToken)
        }

        (async () => {

            try {
                // 获取用户信息
                const _user = await fetchUser();
                setUser(_user);
            } catch (_) {
                alert("401: idToken is invalid.")
                return
            }

            await getNextFeatures("new")
            await getNextFeatures("top")
            await getNextFeatures("finished")

        })();
    }, [idToken])

    /*
    ** functions
    */

    // 获取features
    const getNextFeatures = async (category: string) => {

        const features = await fetchFeatures(category)

        if (category === "new") {
            setNewFeatures(features)
        } else if (category === "top") {
            setTopFeatures(features)
        } else if (category === "finished") {
            setFinishedFeatures(features)
        }
    }

    // 用户up或down指定feature时更新本地数据
    const voteFeatureByIdLocal = (featureId: string, action: VoteAction) => {
        /*
        * 更新user的likes和dislikes列表
        * */
        if (user === undefined) {
            return
        }

        // 深拷贝用户信息，用于编辑likes和dislikes列表
        const _user = _.cloneDeep(user)

        // likes和dislikes具有二异性
        let needDecrement: boolean

        switch (action) {
            case VoteAction.Up:
                if (user.likes !== null && user.likes.includes(featureId)) {
                    return
                }

                needDecrement = user.dislikes !== null && user.dislikes.includes(featureId)
                if (needDecrement) {
                    _user.dislikes = _.remove(user.dislikes, fid => fid !== featureId)
                }
                _user.likes = _.concat(user.likes, featureId)

                break
            case VoteAction.Down:
                if (user.dislikes !== null && user.dislikes.includes(featureId)) {
                    return
                }

                needDecrement = user.likes !== null && user.likes.includes(featureId)
                if (needDecrement) {
                    _user.likes = _.remove(user.likes, fid => fid !== featureId)
                }
                _user.dislikes = _.concat(user.dislikes, featureId)

                break
        }

        // 更新state
        setUser(_user)

        /*
        * 更新features计数
        * */
        const featureList = [newFeatures, topFeatures, finishedFeatures]
        const featureDispatches = [setNewFeatures, setTopFeatures, setFinishedFeatures]

        let i = 0
        while (i < featureList.length) {
            const features = _.cloneDeep(featureList[i])
            const index = _.findIndex(features, feature => feature.id === featureId)

            // 不能出现幺蛾子
            if (index !== -1 && features !== undefined) {
                switch (action) {
                    case VoteAction.Up:
                        features[index].likes += 1
                        if (needDecrement) {
                            features[index].dislikes -= 1
                        }
                        break
                    case VoteAction.Down:
                        features[index].dislikes += 1
                        if (needDecrement) {
                            features[index].likes -= 1
                        }
                        break
                }

                // 更新states
                if (featureDispatches[i] !== undefined) {
                    featureDispatches[i](features)
                }
            }

            // 走你，下一位
            i++;
        }
    }

    const handleDownFeature = async (featureId: string) => {
        // 更新计数和用户的列表
        voteFeatureByIdLocal(featureId, VoteAction.Down)
        // 提交服务端
        await voteFeatureById(featureId, VoteAction.Down)
    }

    const handleUpFeature = async (featureId: string) => {
        // 更新计数和用户的列表
        voteFeatureByIdLocal(featureId, VoteAction.Up)
        // 提交服务端
        await voteFeatureById(featureId, VoteAction.Up)
    }

    /*
    ** views
    **/
    return (
        <Switch>
            <Route path="/features/create">
                <CreateFeaturePage />
            </Route>
            <Route exact path="/features">
                <Redirect to={
                    {
                        pathname: "/features/tab/new",
                        search: location.search
                    }
                } />
            </Route>
            <Route path="/features">
                {user !== undefined
                    ? <FeatureListView
                        user={user}
                        newFeatures={newFeatures}
                        topFeatures={topFeatures}
                        finishedFeatures={finishedFeatures}
                        onUpFeature={(id) => handleUpFeature(id)}
                        onDownFeature={(id) => handleDownFeature(id)}
                        onGetNextFeatures={(category) => getNextFeatures(category)} />
                    : <></>
                }
            </Route>
        </Switch>
    );
}

export default FeaturesRootView;
