import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import "./CreateFeatureView.css"

import TextField from "../../../components/TextField/TextField";
import {createFeature} from "../../../api/featureApi";

function CreateFeaturePage() {
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")

    let history = useHistory();

    const handleSubmit = async () => {
        if (title === "") {
            alert("Title is required")
            return
        }

        if (content === "") {
            alert("Body is required")
            return
        }

        // 开始提交数据
        setLoading(true)
        await createFeature(title, content)

        // 提交完成
        setLoading(false)
        history.goBack();
    }

    return (
        <div className="container">
            <div className="textarea-wrapper">
                <TextField className="title" rows={1} value={title} onChange={setTitle} placeholder="Title"/>
            </div>
            <div className="textarea-wrapper">
                <TextField value={content} rows={5} onChange={setContent} placeholder="Body"/>
            </div>

            <div className="button-group">
                <button className="button Cancel" onClick={() => history.goBack()}>Cancel</button>
                <button className="button Submit" onClick={handleSubmit}>{loading ? 'Submitting...' : 'Submit'}</button>
            </div>
        </div>
    )
}

export default CreateFeaturePage