import React from "react";
import "./CircleButton.css"

function CircleButton() {
    return (
        <button className="circle-button">
            <i className="material-icons">add</i>
        </button>
    )
}

export default CircleButton;