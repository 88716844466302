import React, {useRef, useState} from "react";
import "./FaqCell.css";
import {Faq} from "../../../model/faq";

interface FaqCellProps {
    faq: Faq;
}

function FaqCell(props: FaqCellProps) {

    const [expand, setExpand] = useState(false)
    const panelRef = useRef<HTMLParagraphElement>(null)

    const toggleCell = () => {
        setExpand(!expand)
        // @ts-ignore
        panelRef.current.style.maxHeight = expand ? null : panelRef.current.scrollHeight + 'px';
    }

    const createHTML = () => {
        if (props.faq.content !== undefined) {
            return { __html: props.faq.content }
        }
    }

    return (
        <li>
            <div className="cell">
                <div className="list-cell" onClick={() => toggleCell()}>
                    <p className="list-title">{props.faq.title}</p>
                    {expand
                        ? <i className="material-icons">expand_less</i>
                        : <i className="material-icons">expand_more</i>
                    }
                </div>
                <div className="expand-panel" ref={panelRef}>
                    <div className="list-content" dangerouslySetInnerHTML={createHTML()}/>
                </div>
            </div>
        </li>
    )
}

export default FaqCell;