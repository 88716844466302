import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import "./NavigationBar.css"

function NavigationBar() {
    const location = useLocation();

    return (
        <nav className="nav">
            <NavLink to={{
                pathname: "/features/tab/new",
                search: location.search
            }}>New</NavLink>

            <NavLink to={{
                pathname: "/features/tab/top",
                search: location.search
            }}>Top</NavLink>

            <NavLink to={{
                pathname: "/features/tab/finished",
                search: location.search
            }}>Done</NavLink>
        </nav>

    )
}

export default NavigationBar;