import React from "react";
import "./PrivacyView.css";
import DetailsView from "../../../components/DetailsView/DetailsView";
import {privacy} from "../../../data/privacyData";

function PrivacyView() {

    const documentTitle = "Privacy Policy"
    const contentHtml = privacy.content
    
    return (
        <div className="page">
            <DetailsView documentTitle={documentTitle} html={contentHtml}/>
        </div>
    )

}

export default PrivacyView;