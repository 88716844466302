import React, {useEffect} from "react";

import {faqCategories, faqs} from "../../data/faqData"
import FaqCategoryCell from "./FaqCategoryCell/FaqCategoryCell";
import {Faq} from "../../model/faq";
import _ from "lodash";

import "./FaqRootView.css"

function FaqRootView() {
    useEffect(() => {
        document.title = "FAQ & Help";
    })

    const fetchFaqs = (categoryId: string): Faq[] => {
        return _.filter(faqs, (faq) => {
            return faq.categoryId === categoryId
        })
    }

    return (
        <div className="faq">
            <ul className="list">
                {faqCategories.map(category => <FaqCategoryCell key={category.id}
                                                                category={category}
                                                                faqs={fetchFaqs(category.id)}/>)}
            </ul>

            <p>Unable to find an answer? Feel free to contact us at <a href="mailto:hello@hiyak.com">hello@hiyak.com</a>
            </p>
        </div>
    )
}

export default FaqRootView;