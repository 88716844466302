import React from "react";
import FaqCell from "../FaqCell/FaqCell";
import {Faq} from "../../../model/faq";

interface FaqListViewProps {
    faqs: Faq[];
}

function FaqListView(props: FaqListViewProps) {
    return <div>{props.faqs.map(faq => <FaqCell key={faq.id} faq={faq}/>)}</div>
}

export default FaqListView;